<template>
  <div class="regisetr">
    <heads></heads>
    <div class="main">
      <div class="main_box">
        <div class="container">
          <div class="content">
            <el-form class="register" ref="form" :model="form" :rules="rules">
              <el-form-item label="账号" prop="account">
                <el-input v-model="form.account"></el-input>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <el-input v-model="form.password"></el-input>
              </el-form-item>
              <el-form-item label="邮箱" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button class="go-register" type="primary" @click="submitForm()">注册</el-button>
              </el-form-item>

              <div class="goLogin">
                <router-link to="/login">去登录</router-link>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/noLoginHeade.vue";
import foot from "@components/foot.vue";
import { register } from "@api/user";
export default {
  components: {
    heads,
    foot,
  },
  data() {
    return {
      form: {
        account: "",
        password: "",
        email: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted: function () {
    let that = this;
    window.onresize = function () {
      return (function () {
        that.$router.go(0);
      })();
    };
  },
  methods: {
    submitForm() {
      let that = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          register(that.form)
            .then(function (data) {
              that.$dialog.success("");
            })
            .catch(function (e) {
              that.$dialog.error(e.msg);
            });
        } else {
          that.$dialog.error(e.msg);
        }
      });
    },
    onLogin() {
      window.location.href = "/login";
    },
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>
<style lang="less">
.regisetr>.main {
  width: 100%;
  min-width: 1484px;
  height: 1095px;
  background: url(../assets/images/register.jpg);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
}

.main_box {
  width: 1484px;
  height: 1095px;
  margin: 0 auto;
}

.container {
  width: 820px;
  height: 573px;
  padding-top: 220px;
  padding-left: 370px;
  display: flex;
}

.head {
  text-decoration: none;
  color: #000;
  text-align: center;
  font-size: 18px;
  color: #07c160;
}

.content {
  padding-top: 176px;
  padding-left: 186px;
  width: 440px;
}

.el-form-item {
  margin-bottom: 44px;
}

.el-form-item__label {
  font-size: 20px;
  padding: 0 38px 0 0;
  font-size: 18px;
  color: #574136;
  width: 130px !important;
  text-align: left !important;
}

.el-input__inner {
  margin-top: 5px;
  height: 30px;
  border-radius: 30px;
}

.el-input {
  width: 310px !important;
  background: transparent !important;
}

.el-input__inner {
  background: transparent !important;
  border: none;
}

.el-form-item__content {
  text-align: center;
}

.van-button--primary {
  color: #fff;
  background-color: #c7b2a8;
  border: 1px solid #c7b2a8;
}

.goLogin {
  position: relative;
  right: 0px;
  margin-top: -30px;
  text-align: right;
  padding-right: 20px;
}

.goLogin>a {
  font-size: 16px;
  color: #bfa9a0;
}

.go-register,
.go-register:active,
.go-register:visited,
.go-register:hover {
  width: 400px;
  color: #fff !important;
  background-color: #c7b2a8 !important;
  border: 0.00521rem solid #c7b2a8 !important;
}

.register .el-form-item__error {
  left: 130px;
}
</style>